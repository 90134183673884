import {mapActions, mapMutations} from "vuex";
import {validationMixin} from "vuelidate";
import {email, required} from "vuelidate/lib/validators";
export default {
  name: 'add-comment',
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        slug: '',
        text: ''
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      text: {
        required,
      },
    }
  },
  computed:{
   textErrors() {
      let error = [];
      if (!this.$v.payload.text.$dirty) {
        return error;
      }
      if (!this.$v.payload.text.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.text) {
        this.validationErrors.text.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  created() {
    console.log(this.$route.params.item);
    this.payload.slug = this.$route.params.item
  },
  methods:{
    ...mapActions({
      sendComment:`card/ADD_COMMENT`
    }),
    ...mapMutations({
      changePopupAddComment: `card/CHANGE_POPUP_ADD_COMMENT`,
    }),
    submit(){
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {}
        obj.text = {text:this.payload.text}
        obj.slug = this.payload.slug
        this.sendComment(obj).then(()=>{
          this.$toasted.success(this.$t('commentSend'));
          this.changePopupAddComment(false)
        })
      }
    }
  }
}